import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const BehaviourGroup = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourGroup"))
);
const BehaviourCategory = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourCategory"))
);
const BehaviourCategoryForm = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourCategoryForm"))
);
const BehaviourTrans = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourTrans"))
);
const BehaviourTransForm = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourTransForm"))
);
const BehaviourIncident = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourIncident"))
);
const CategoryIncident = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourIncidentReport"))
);
const BehaviourCardView = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourCardView"))
);

const BehaviourTraitsHabits = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourTraitsHabits"))
);

const BehaviourMessage = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourMessage"))
);

const BehaviourMessageForm = SuspenseWrapper(
  lazy(() => import("components/behaviour/BehaviourMessageForm"))
);

const BehaviourRoutes = {
  path: "behaviour",
  element: <Outlet />,
  children: [
    {
      path: "group",
      element: <BehaviourGroup />,
    },
    {
      path: "category",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <BehaviourCategory />,
        },
        {
          path: "add",
          element: <BehaviourCategoryForm />,
        },
        {
          path: "edit/:hash",
          element: <BehaviourCategoryForm />,
        },
        {
          path: "message",
          element: <BehaviourMessage />,
        },
        {
          path: "message/add",
          element: <BehaviourMessageForm />,
        },
        {
          path: "message/edit/:hash",
          element: <BehaviourMessageForm />,
        },
      ],
    },
    {
      path: "trans",
      element: <Outlet />,
      children: [
        {
          path: "",
          element: <BehaviourTrans />,
        },
        {
          path: "add",
          element: <BehaviourTransForm />,
        },
        {
          path: "edit/:hash",
          element: <BehaviourTransForm />,
        },
      ],
    },
    {
      path: "incident",
      element: <BehaviourIncident />,
    },
    {
      path: "categoryincident",
      element: <CategoryIncident />,
    },
    {
      path: "card",
      element: <BehaviourCardView />,
    },
    {
      path: "triatshabits",
      element: <BehaviourTraitsHabits />,
    }
  ],
};

export default BehaviourRoutes;
