import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const SmsCategoryList = SuspenseWrapper(
  lazy(() => import("components/sms/SmsCategoryList"))
);
const SmsList = SuspenseWrapper(
  lazy(() => import("components/sms/send-v4/OverAllView"))
);
const StaffSmsList = SuspenseWrapper(
  lazy(() => import("components/sms/send-v4/OverAllView"))
);
const SmsForm = SuspenseWrapper(lazy(() => import("components/sms/send-v4/SmsForm")));
const SmsFormsimple = SuspenseWrapper(lazy(() => import("components/sms/send-v4/SmsFormsimple")));
const StaffSmsForm = SuspenseWrapper(
  lazy(() => import("components/sms/send-v4/SmsForm"))
);
const SmsDeliveryList = SuspenseWrapper(
  lazy(() => import("components/sms/send/SmsDeliveryList"))
);
const SmsCreditReport = SuspenseWrapper(
  lazy(() => import("components/sms/reports/CreditReport"))
);
const SmsDebitReport = SuspenseWrapper(
  lazy(() => import("components/sms/reports/DebitReport"))
);

const SmsRoutes = {
  path: "sms",
  element: <Outlet />,
  children: [
    {
      path: "category-list",
      element: <SmsCategoryList />,
    },
    {
      path: "list",
      element: <SmsList mode="P" />,
    },
    {
      path: "staff-list",
      element: <StaffSmsList mode="R" />,
    },
    {
      path: "add",
      element: <SmsForm pageMode="P" />,
    },
    {
      path: "pageadd",
      element: <SmsFormsimple pageMode="P" />,
    },
    {
      path: "staff-add",
      element: <StaffSmsForm pageMode="R" />,
    },
    {
      path: "copy/:hash",
      element: <SmsForm pageMode="P" />,
    },
    {
      path: "staff-copy/:hash",
      element: <StaffSmsForm pageMode="R" />,
    },
    {
      path: "sms-delivery/:hash",
      element: <SmsDeliveryList mode="P" />,
    },
    {
      path: "staff-sms-delivery/:hash",
      element: <SmsDeliveryList mode="R" />,
    },
    {
      path: "credit-report",
      element: <SmsCreditReport />,
    },
    {
      path: "debit-report",
      element: <SmsDebitReport />,
    },
  ],
};

export default SmsRoutes;
