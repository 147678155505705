import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const EnqStageList = SuspenseWrapper(
  lazy(() => import("components/admission/EnqStageList"))
);
const EnqStatusList = SuspenseWrapper(
  lazy(() => import("components/admission/EnqStatusList"))
);
const EnqFormList = SuspenseWrapper(
  lazy(() => import("components/admission/EnqFormList"))
);
const EnqForm = SuspenseWrapper(lazy(() => import("components/admission/EnqForm")));
const EnqFormView = SuspenseWrapper(
  lazy(() => import("components/admission/EnqFormView"))
);
const EnquirySettingsForm = SuspenseWrapper(
  lazy(() => import("components/admission/EnqSettingsForm"))
);

const AdmissionRoutes = {
  path: "admission",
  element: <Outlet />,
  children: [
    {
      index: true,
      path: "enqstage-list",
      element: <EnqStageList />,
    },
    {
      path: "enqstatus-list",
      element: <EnqStatusList />,
    },
    {
      path: "enqform",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <EnqFormList />,
        },
        {
          path: "add",
          element: <EnqForm />,
        },
        {
          path: "edit/:hash",
          element: <EnqForm />,
        },
        {
          path: "view/:hash",
          element: <EnqFormView />,
        },
      ],
    },
    {
      path: "settings",
      element: <EnquirySettingsForm />,
    },
  ],
};

export default AdmissionRoutes;
