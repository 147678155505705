import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const CompartList = SuspenseWrapper(
  lazy(() => import("components/master/CompartList"))
);
const DeptList = SuspenseWrapper(
  lazy(() => import("components/master/DeptList"))
);
const BatchList = SuspenseWrapper(
  lazy(() => import("components/master/BatchList"))
);
const GradeList = SuspenseWrapper(
  lazy(() => import("components/master/GradeList"))
);
const SectionList = SuspenseWrapper(
  lazy(() => import("components/master/SectionList"))
);
const SemesterList = SuspenseWrapper(
  lazy(() => import("components/master/SemesterList"))
);
const CommunityList = SuspenseWrapper(
  lazy(() => import("components/master/CommunityList"))
);
const CommunityCategoryList = SuspenseWrapper(
  lazy(() => import("components/master/CommunityCategoryList"))
);
const SubjectTypeList = SuspenseWrapper(
  lazy(() => import("components/master/SubjectTypeList"))
);
const SubjectList = SuspenseWrapper(
  lazy(() => import("components/master/SubjectList"))
);
const ScholarCategoryList = SuspenseWrapper(
  lazy(() => import("components/master/ScholarCategoryList"))
);
const DesignationList = SuspenseWrapper(
  lazy(() => import("components/master/DesignationList"))
);
const ActivityType = SuspenseWrapper(
  lazy(() => import("components/master/ActivityType"))
);
const PostCategoryList = SuspenseWrapper(
  lazy(() => import("components/master/PostCategoryList"))
);
const SettingsForm = SuspenseWrapper(
  lazy(() => import("components/master/SettingsForm"))
);
const MotherTongueList = SuspenseWrapper(
  lazy(() => import("components/master/MotherTongueList"))
);
const ReligionList = SuspenseWrapper(
  lazy(() => import("components/master/ReligionList"))
);
const TransportModeList = SuspenseWrapper(
  lazy(() => import("components/master/TransportModeList"))
);
const ContactCategory = SuspenseWrapper(
  lazy(() => import("components/master/ContactCategory"))
);
const ContactList = SuspenseWrapper(
  lazy(() => import("components/master/ContactList"))
);
const ContactForm = SuspenseWrapper(
  lazy(() => import("components/master/ContactForm"))
);
const PostTemplateList = SuspenseWrapper(
  lazy(() => import("components/master/PostTemplateList"))
);
const MasterRoutes = {
  path: "master",
  element: <Outlet />,
  children: [
    {
      path: "compartment-list",
      element: <CompartList />,
    },
    {
      path: "post-template",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <PostTemplateList />,
        },
      ],
    },
    {
      path: "department-list",
      element: <DeptList />,
    },
    {
      path: "batch-list",
      element: <BatchList />,
    },
    {
      path: "grade-list",
      element: <GradeList />,
    },
    {
      path: "section-list",
      element: <SectionList />,
    },
    {
      path: "scholar-category-list",
      element: <ScholarCategoryList />,
    },
    {
      path: "semester-list",
      element: <SemesterList />,
    },
    {
      path: "subject-type-list",
      element: <SubjectTypeList />,
    },
    {
      path: "subject-list",
      element: <SubjectList />,
    },
    {
      path: "designation-list",
      element: <DesignationList />,
    },
    {
      path: "activity-type-list",
      element: <ActivityType />,
    },
    {
      path: "post-category-list",
      element: <PostCategoryList />,
    },
    {
      path: "community-list",
      element: <CommunityList />,
    },
    {
      path: "community-category-list",
      element: <CommunityCategoryList />,
    },
    {
      path: "settings",
      element: <SettingsForm />,
    },
    {
      path: "mother-tongue-list",
      element: <MotherTongueList />,
    },
    {
      path: "religion-list",
      element: <ReligionList />,
    },
    {
      path: "transport-mode-list",
      element: <TransportModeList />,
    },
    {
      path: "contact-category",
      element: <ContactCategory />,
    },
    {
      path: "contact-list",
      element: <ContactList />,
    },
    {
      path: "contact-add",
      element: <ContactForm />,
    },
    {
      path: "contact-edit/:hash",
      element: <ContactForm />,
    },
  ],
};

export default MasterRoutes;
