import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const Feedback = SuspenseWrapper(lazy(() => import("components/feedback/OverView")));
const CategoryForm = SuspenseWrapper(lazy(() => import("components/feedback/CategoryForm")));
const CategoryList = SuspenseWrapper(lazy(() => import("components/feedback/CategoryList")));

const FeedbackRoutes = {
  path: "feedback",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <Feedback />,
    },
    {
      path: "add",
      element: <CategoryForm  />,
    },
    {
        path: "edit/:hash",
      element: <CategoryForm />,
    },
    {
        path: "list",
        element: <CategoryList  />,
      },

  ],
};

export default FeedbackRoutes;
