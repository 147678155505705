import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const TimeTableList = SuspenseWrapper(
  lazy(() => import("components/timetable/TimeTableList"))
);
const TTMasterList = SuspenseWrapper(
  lazy(() => import("components/timetable/TTMasterList"))
);
// const TTMasterForm = SuspenseWrapper(
//   lazy(() => import("components/timetable/TTMasterForm"))
// );
// const TTMapForm = SuspenseWrapper(lazy(() => import("components/timetable/TTMapForm")));
const TTMapView = SuspenseWrapper(lazy(() => import("components/timetable/TTMapView")));
const TTStaffList = SuspenseWrapper(
  lazy(() => import("components/timetable/TTStaffList"))
);

const TimeTableStep = SuspenseWrapper(
  lazy(() => import("components/timetable/TimeTableStep"))
);

const TimeTableMapStep = SuspenseWrapper(
  lazy(() => import("components/timetable/TimeTableMapStep"))
);

const TimetableRoutes = {
  path: "timetable",
  element: <Outlet />,
  children: [
    {
      path: "tt-map",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TimeTableList />,
        },
        {
          path: "add",
          element: <TimeTableMapStep />,
        },
        {
          path: "edit/:hash",
          element: <TimeTableMapStep />,
        },
        {
          path: "view/:hash",
          element: <TTMapView />,
        },
      ],
    },
    {
      path: "tt-master",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TTMasterList />,
        },
        {
          path: "add",
          element: <TimeTableStep />,
        },
        {
          path: "edit/:hash",
          element: <TimeTableStep />,
        },
      ],
    },
    {
      path: "tt-staff",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <TTStaffList />,
        },
      ],
    },
  ],
};

export default TimetableRoutes;
