import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const Communication = SuspenseWrapper(lazy(() => import("components/communication/Communication")));
const PostForm=SuspenseWrapper(lazy(() => import("components/communication/posts/v2/PostForm")))
const Sms = SuspenseWrapper(lazy(() => import("components/communication/sms/send-v4/OverAllView")));
const SmsForm = SuspenseWrapper(lazy(() => import("components/communication/sms/send-v4/SmsForm")));
const Whatsapp = SuspenseWrapper(lazy(() => import("components/communication/whatsapp/send/OverAllView")));
const WhatsappForm = SuspenseWrapper(lazy(() => import("components/communication/whatsapp/send/WhatsappForm")));
const CommunicationRoutes = {
  path: "communication",
  element: <Outlet />,
  children: [
    {
      path: "",
      index: true,
      element: <Communication pageMode="P"/>,
    },
    {
      path: "postadd",
      index: true,
      element: <PostForm pageMode="P"/>,
    },
    {
      path: "sms",
      element:<Outlet />,
      children: [
        {
          path: "sms-scholar",
          index: true,
          element: <Sms pageMode="P"/>,
        },
        {
          path: "sms-add",
          index: true,
          element: <SmsForm pageMode="P"/>,
        },
      ]
    },
    {
      path: "voice-call",
      element:<Outlet />,
      children: [
        {
          path: "voicecall-scholar",
          index: true,
          element: <Sms pageMode="P"/>,
        },
        {
          path: "voicecall-add",
          index: true,
          element: <SmsForm pageMode="P"/>,
        },
      ]
    },  
    {
      path: "whatsapp",
      element:<Outlet />,
      children: [
        {
          path: "whatsapp-scholar",
          index: true,
          element: <Whatsapp pageMode="P"/>,
        },
        {
          path: "whatsapp-add",
          index: true,
          element: <WhatsappForm pageMode="P"/>,
    
        },
      ]
    }
  ],
};
export default CommunicationRoutes;
