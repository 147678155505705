import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ScholarList = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarList"))
);
const ScholarForm = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarForm"))
);
const ScholarImportForm = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarImportForm"))
);
const ScholarImportList = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarImportList"))
);
const ScholarImportStatusList = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarImportStatusList"))
);
const OptionalSubjectList = SuspenseWrapper(
  lazy(() => import("components/scholar/OptionalSubjectList"))
);
const OptionalSubjectBulk = SuspenseWrapper(
  lazy(() => import("components/scholar/OptionalSubjectBulk"))
);
const ScholarClassChangeList = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarClassChangeList"))
);
const PromotionList = SuspenseWrapper(
  lazy(() => import("components/scholar/PromotionList"))
);
const BatchMigrationList = SuspenseWrapper(
  lazy(() => import("components/scholar/BatchMigrationList"))
);
const BirthdayList = SuspenseWrapper(
  lazy(() => import("components/scholar/reports/BirthdayList"))
);
const CommunityReport = SuspenseWrapper(
  lazy(() => import("components/scholar/reports/CommunityReport"))
);
const CommunityCategoryReport = SuspenseWrapper(
  lazy(() => import("components/scholar/reports/CommunityReport"))
);
const MobileAppList = SuspenseWrapper(
  lazy(() => import("components/scholar/reports/MobileAppList"))
);
const StudentStrengthList = SuspenseWrapper(
  lazy(() => import("components/scholar/reports/StudentStrengthList"))
);
const ScholarDetForm = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarDetForm"))
);
const ScholarRecords = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarRecords"))
);
const ScholarRecordsProfile = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarRecordsProfile"))
);
const ScholarNationLangForm = SuspenseWrapper(
  lazy(() => import("components/scholar/ScholarNationLangForm"))
);
const ScholarDocumentsForm = SuspenseWrapper(
  lazy(() => import("components/documents/ScholarDocumentsForm"))
);
const ScholarDocumentsList = SuspenseWrapper(
  lazy(() => import("components/documents/ScholarDocumentsList"))
);
const ScholarRoutes = {
  path: "scholar",
  element: <Outlet />,
  children: [
    {
      path: "nationalLang",
      element: <ScholarNationLangForm />,
    }, 
    {
      path: "list",
      element: <ScholarList />,
    },
    {
      path: "add",
      element: <ScholarForm />,
    },
    {
      path: "edit/:hash",
      element: <ScholarForm />,
    },
    {
      path: "import-list",
      element: <ScholarImportList />,
    },
    {
      path: "import",
      element: <ScholarImportForm />,
    },
    {
      path: "import-status/:hash",
      element: <ScholarImportStatusList />,
    },
    {
      path: "optional-subjects",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <OptionalSubjectList />,
        },
        {
          path: "bulk-mapping",
          element: <OptionalSubjectBulk />,
        },
      ],
    },
    {
      path: "class-change",
      element: <ScholarClassChangeList />,
    },
    {
      path: "promotion",
      element: <PromotionList />,
    },
    {
      path: "batch-migration",
      element: <BatchMigrationList />,
    },
    {
      path: "birthday-report",
      element: <BirthdayList />,
    },
    {
      path: "community-category-report",
      element: <CommunityCategoryReport mode="CC" />,
    },
    {
      path: "community-report",
      element: <CommunityReport mode="C" />,
    },
    {
      path: "mobileapp-report",
      element: <MobileAppList />,
    },
    {
      path: "strength-report",
      element: <StudentStrengthList />,
    },
    {
      path: "det",
      element: <ScholarDetForm />,
    },
    {
      path: "det/edit/:hash",
      element: <ScholarDetForm />,
    },
    {
      path: "records",
      element: <ScholarRecords />,
    },
    {
      path: "records/profile",
      element: <ScholarRecordsProfile />,
    },
    {
      path: "scholar_docs",
      element: <Outlet />,
      children: [
        {
          path: "list",
          element: <ScholarDocumentsList  />,
        },
        {
            path: "add",
            element: <ScholarDocumentsForm />,
          },
          {
            path: "edit/:hash",
            element: <ScholarDocumentsForm />,
          },
      ],
    },
  ],
};

export default ScholarRoutes;
