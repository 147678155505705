import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const WhatsappList = SuspenseWrapper(
  lazy(() => import("components/whatsapp/send/OverAllView"))
);
const StaffWhatsappList = SuspenseWrapper(
  lazy(() => import("components/whatsapp/send/OverAllView"))
);
const WhatsappForm = SuspenseWrapper(
  lazy(() => import("components/whatsapp/send/WhatsappForm"))
);
const StaffWhatsappForm = SuspenseWrapper(
  lazy(() => import("components/whatsapp/send/WhatsappForm"))
);
// const WhatsappDeliveryList = SuspenseWrapper(
//   lazy(() => import("components/whatsapp/send/WhatsappDeliveryList"))
// );
const WhatsappCreditReport = SuspenseWrapper(
  lazy(() => import("components/whatsapp/reports/CreditReport"))
);
const WhatsappDebitReport = SuspenseWrapper(
  lazy(() => import("components/whatsapp/reports/DebitReport"))
);

const WhatsappRoutes = {
  path: "whatsapp",
  element: <Outlet />,
  children: [
    {
      path: "list",
      element: <WhatsappList mode="P" />,
    },
    {
      path: "staff-list",
      element: <StaffWhatsappList mode="R" />,
    },
    {
      path: "add",
      element: <WhatsappForm pageMode="P" />,
    },
    {
      path: "copy/:hash",
      element: <WhatsappForm pageMode="P" />,
    },
    {
      path: "staff-add",
      element: <StaffWhatsappForm pageMode="R" />,
    },
    {
      path: "credit-report",
      element: <WhatsappCreditReport />,
    },
    {
      path: "debit-report",
      element: <WhatsappDebitReport />,
    },
  ],
};

export default WhatsappRoutes;
