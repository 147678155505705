import React, { lazy } from "react";
import { Outlet } from "react-router-dom";
import SuspenseWrapper from "../SuspenseWrapper";

const ShowCasePosts = SuspenseWrapper(lazy(() => import("components/showcase/OverAllView")));
const ShowCaseCatList = SuspenseWrapper(lazy(() => import("components/showcase/CategoryList")));
const ShowCasePostForm = SuspenseWrapper(lazy(() => import("components/showcase/PostForm")));

const ShowCaseRoutes = {
  path: "showcase",
  element: <Outlet />,
  children: [
    {
      path: "list/:hash",
      index: true,
      element: <ShowCasePosts/>,
    },
    {
      path: "add/:hash",
      element: <ShowCasePostForm/>,
    },
    {
      path: "edit/:hash",
      element: <ShowCasePostForm />,
    },
    {
      path: "category",
      element: <ShowCaseCatList />,
    },

  ],
};

export default ShowCaseRoutes;
